<template>
  <div
    class="bg-white hover:shadow-card border-2 border-solid border-card-border w-cardNetwork rounded-lg p-4 flex flex-col gap-y-5"
  >
    <div class="flex-1 flex flex-col gap-y-3">
      <!-- Name & Avatar -->
      <div class="flex flex-col gap-y-2">
        <!-- Doctor Image Profile -->
        <div class="relative h-181 overflow-hidden">
          <img
            :src="$checkAvatar(data.image)"
            class="w-full h-181 object-cover rounded"
          />
        </div>
        <!-- Full Name -->
        <h1
          class="text-black text-base font-bold font-EffraR"
          style="line-height: 1.63;"
        >
          {{ `${data.title || "Dr."} ${data.firstName} ${data.lastName}` }}
        </h1>
      </div>

      <!-- Other Informations -->
      <div class="flex flex-col gap-y-0">
        <div
          class="font-EffraR text-base text-black leading-loose flex items-start gap-x-2"
        >
          <div class="w-[24px] h-[24px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="none"
              viewBox="0 0 28 28"
              class="h-6 w-6"
            >
              <path
                fill="#111"
                d="M10 5v2H8v4c0 2.21 1.79 4 4 4s4-1.79 4-4V7h-2V5h3c.552 0 1 .448 1 1v5c0 2.973-2.162 5.44-5 5.917V18.5c0 1.933 1.567 3.5 3.5 3.5 1.497 0 2.775-.94 3.275-2.263C18.728 19.27 18 18.22 18 17c0-1.657 1.343-3 3-3s3 1.343 3 3c0 1.371-.92 2.527-2.176 2.885C21.21 22.252 19.059 24 16.5 24c-3.038 0-5.5-2.462-5.5-5.5v-1.583C8.162 16.441 6 13.973 6 11V6c0-.552.448-1 1-1h3zm11 11c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
              />
            </svg>
          </div>
          <span
            class="truncate"
            v-if="data.specialities && data.specialities.length"
          >
            {{ data.specialities[0].name }}
            <template v-if="data.specialities[1]">...</template>
          </span>
        </div>
        <div
          class="font-EffraR text-base text-black leading-loose flex items-center gap-x-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="h-6 w-6"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="12 6 12 12 16 14"></polyline>
          </svg>
          {{
            data.experienceSince
              ? `${parseExperience(data.experienceSince)} ans d'expérience`
              : "--"
          }}
        </div>
        <div
          class="font-EffraR text-base text-black leading-loose flex items-center gap-x-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            class="h-6 w-6"
          >
            <path
              fill="#111"
              fill-rule="evenodd"
              d="M12 2c-2.122 0-4.157.843-5.657 2.343S4 7.878 4 10c0 3.098 2.016 6.104 4.226 8.437 1.085 1.145 2.173 2.082 2.99 2.734.305.242.571.444.784.602.213-.158.479-.36.783-.602.818-.651 1.906-1.589 2.991-2.734C17.984 16.104 20 13.098 20 10c0-2.122-.843-4.157-2.343-5.657S14.122 2 12 2zm0 21l-.555.832-.003-.002-.007-.005-.023-.015-.082-.057c-.072-.05-.174-.12-.302-.214-.258-.186-.622-.457-1.057-.804-.87-.692-2.032-1.692-3.197-2.922C4.484 17.396 2 13.902 2 10c0-2.652 1.054-5.196 2.929-7.071C6.804 1.054 9.348 0 12 0s5.196 1.054 7.071 2.929C20.946 4.804 22 7.348 22 10c0 3.902-2.484 7.396-4.774 9.813-1.165 1.23-2.327 2.23-3.197 2.922-.435.347-.8.618-1.057.804-.128.093-.23.165-.302.214l-.082.057-.023.015-.007.005-.002.001s-.001.001-.556-.831zm0 0l.555.832c-.336.224-.774.224-1.11 0L12 23z"
              clip-rule="evenodd"
            />
            <path
              fill="#111"
              fill-rule="evenodd"
              d="M12 8c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm-4 2c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4z"
              clip-rule="evenodd"
            />
          </svg>

          {{ data.cityName || "--" }}
        </div>
      </div>
    </div>

    <!-- Actions Default -->
    <dok-button
      bg="trans"
      size="md"
      custom-class="font-EffraM flex items-center gap-x-1 hover:bg-dokBlue-ultra hover:text-white"
      @click.native="onClickAdd"
    >
      <svg
        width="19"
        height="18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5 3.75v10.5M4.25 9h10.5"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      Ajouter à mon réseau
    </dok-button>
  </div>
</template>

<script>
import moment from "moment";
import { EventBus } from "@/event-bus";

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userDropDown: false
    };
  },
  methods: {
    async onClickAdd() {
      this.$vs.loading();
      await this.$store.dispatch("social/SENT_INVITATION", {
        data: {
          recipientId: this.data.objectID
        },
        onData: ({ ok, data, message }) => {
          if (ok) {
            EventBus.$emit("flashDok", {
              type: "success",
              message: "Invitation envoyée avec succès",
              timerAlert: 8000
            });

            return this.$vs.loading.close();
          }
        }
      });
    },
    parseExperience(date) {
      return moment(date).fromNow(true);
    }
  }
};
</script>

<style></style>
