var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('header',{staticClass:"bg-white fixed w-full px-10 flex flex-col justify-center z-2",staticStyle:{"height":"118px"}},[_c('nav',{staticClass:"flex flex-row gap-x-8"},_vm._l((_vm.tabs),function(tab,index){return _c('span',{key:index,staticClass:"\n          cursor-pointer\n          py-4\n          flex\n          items-center\n          gap-x-2\n          border-0 border-b-2 border-solid\n        ",class:tab.id === _vm.currentTab
            ? ' border-dokBlue-ultra text-dokBlue-ultra font-EffraM'
            : 'text-labelGray font-EffraR border-white',on:{"click":function($event){_vm.currentTab = tab.id}}},[_c('span',{domProps:{"innerHTML":_vm._s(tab.icon)}}),_vm._v(" "+_vm._s(tab.name)+" ")])}),0)]),_c('main',{staticClass:"px-10 py-100 flex flex-col gap-y-3"},[(_vm.currentTab === 3)?[_c('header-search',{attrs:{"position-card":"search"}}),_c('search')]:_vm._e(),(_vm.currentTab === 2)?[_c('invitations')]:_vm._e(),(_vm.currentTab === 1)?[_c('header-search',{attrs:{"position-card":"my_network"}}),_c('my-network')]:_vm._e(),(_vm.currentTab === 4)?[_c('chat')]:_vm._e()],2),_c('modal',{attrs:{"isActive":_vm.modal.is,"exitModal":function () {},"min-width":400,"hidden-header":true}},[_c('div',{staticClass:"p-5 flex flex-col gap-y-8"},[(_vm.modal.type === 'INVITER_CONFERE')?[_c('div',{staticClass:"flex justify-between items-center"},[_c('h1',{staticClass:"flex items-center gap-x-1 text-black text-2xl font-EffraR"},[_c('svg',{attrs:{"width":"18","height":"18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M16.5 1.5 8.25 9.75M16.5 1.5l-5.25 15-3-6.75-6.75-3 15-5.25z","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" Inviter confrère ")]),_c('span',{staticClass:"cursor-pointer",on:{"click":function () {
                this$1.modal.is = false;
                this$1.modal.type = '';
              }}},[_c('svg',{attrs:{"width":"24","height":"24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M18 6 6 18M6 6l12 12","stroke":"#111","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]),_c('sendRequest')]:_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }