<template>
  <div class="divide-y divide-x-0 divide-solid divide-gray-200">
    <div
      v-for="(user, index) in Array(5)"
      :key="index"
      class="h-[64px] px-8 flex items-center justify-between cursor-pointer"
      :class="
        index === 0
          ? 'bg-dokBlue-lighter border-0 border-l-4 border-solid border-dokBlue-ultra'
          : 'hover:bg-dokBlue-lighter'
      "
    >
      <div class="flex-1 flex flex-row items-center gap-x-2">
        <div>
          <div
            class="h-8 w-8 rounded-full bg-reddok border border-solid border-gray-100"
          ></div>
        </div>
        <h1 class="text-base font-EffraR">Abderrahman Nazih</h1>
      </div>

      <div>
        <div
          class="h-6 w-6 rounded-full bg-rosDok-ultra font-EffraR text-lg text-white flex items-center justify-center"
        >
          5
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>