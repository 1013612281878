<template>
  <div>
    <div class="bg-white rounded-lg p-6 flex flex-col gap-y-6">
      <template v-if="!loader">
        <!-- Title -->
        <h1 class="font-EffraM text-xl text-black">
          {{ data.length }} praticiens trouvés
        </h1>
        <!-- Card Tempalte  -->
        <div class="flex flex-wrap gap-x-5 gap-y-4" v-if="data.length">
          <!-- FIRST CARD INFORMATION -->
          <div
            class="bg-[#f6faff] hover:shadow-card w-cardNetwork rounded-lg p-4 flex flex-col gap-y-5"
          >
            <div class="flex-1 flex flex-col gap-y-3">
              <!-- Name & Avatar -->
              <div class="flex flex-col gap-y-2">
                <!-- Doctor Image Profile -->
                <div class="relative h-181 overflow-hidden">
                  <img
                    src="/artwork/no-data.svg"
                    class="h-181 w-full object-cover"
                  />
                </div>
              </div>

              <!-- Other Informations -->
              <div class="flex flex-col gap-y-0">
                <div class="text-base font-EffraM text-dokBlue-ultra">
                  Le confrère que vous recherchez n'a pas encore rejoint dok
                  Pro?
                </div>
                <div class="text-sm font-EffraR text-dokBlue-ultra">
                  Invitez des praticiens pour qu'ils profitent de toutes les
                  fonctionnalités que nous offrons.
                </div>
              </div>
            </div>

            <dok-button bg="blue" size="md" @click.native="emitInviterModal"
              >Inviter des confères</dok-button
            >
          </div>

          <!-- DOCTORS -->
          <card v-for="(card, index) in data" :key="index" :data="card" />
        </div>
        <!-- if null -->
        <div
          class="w-full flex flex-col gap-y-8 items-center justify-center"
          v-if="!data.length"
        >
          <svg
            width="98"
            height="98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="text-dokBlue-ultra"
          >
            <path
              d="M96.05 38.54c0-6.612-3.63-12.39-8.996-15.451a11.356 11.356 0 0 0 2.632-7.279c0-6.292-5.119-11.41-11.41-11.41-6.292 0-11.41 5.118-11.41 11.41 0 2.765.99 5.303 2.632 7.279-5.368 3.061-8.997 8.839-8.997 15.45v5.456a3.228 3.228 0 0 0 3.228 3.227h29.093a3.228 3.228 0 0 0 3.228-3.227V38.54zM78.275 10.855a4.961 4.961 0 0 1 4.956 4.955 4.961 4.961 0 0 1-4.956 4.955 4.961 4.961 0 0 1-4.955-4.955 4.961 4.961 0 0 1 4.956-4.955zM66.956 40.767V38.54c0-6.242 5.078-11.32 11.32-11.32 6.24 0 11.319 5.078 11.319 11.32v2.227H66.956zM37.5 43.995V38.54c0-6.612-3.63-12.39-8.997-15.451a11.355 11.355 0 0 0 2.633-7.279c0-6.292-5.119-11.41-11.41-11.41-6.293 0-11.41 5.118-11.41 11.41 0 2.765.99 5.303 2.631 7.279C5.58 26.15 1.95 31.928 1.95 38.539v5.456a3.228 3.228 0 0 0 3.227 3.227h29.094a3.228 3.228 0 0 0 3.228-3.227zm-17.775-33.14a4.961 4.961 0 0 1 4.955 4.955 4.961 4.961 0 0 1-4.955 4.955 4.961 4.961 0 0 1-4.955-4.955 4.961 4.961 0 0 1 4.955-4.955zM8.405 40.767V38.54c0-6.242 5.078-11.32 11.32-11.32 6.24 0 11.319 5.078 11.319 11.32v2.227H8.405zm72.153 13.674a3.227 3.227 0 0 0-4.565 0l-10.91 10.91a3.227 3.227 0 1 0 4.564 4.565l5.401-5.4v18.073H22.952V56.723a3.228 3.228 0 0 0-6.455 0v29.094a3.228 3.228 0 0 0 3.227 3.227h58.551a3.228 3.228 0 0 0 3.228-3.227V64.515l5.4 5.4a3.227 3.227 0 1 0 4.565-4.564l-10.91-10.91z"
              fill="currentColor"
              stroke="currentColor"
            />
          </svg>

          <div class="flex flex-col w-full items-center justify-center gap-y-3">
            <h1 class="font-EffraM text-2xl text-black">
              Vous n'avez pas encore ajouter de confrères à votre réseau
            </h1>

            <p class="text-center font-EffraR text-base">
              Envoyez des invitations à vos confrères pour rejoindre votre
              réseau d'adressage.
            </p>
          </div>

          <dok-button
            @click.native="emitInviter"
            size="lg"
            custom-class="px-10 font-EffraM flex items-center gap-x-2"
          >
            <svg
              width="18"
              height="18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.75v10.5M3.75 9h10.5"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Rechercher
          </dok-button>
        </div>
      </template>
      <template v-else>
        <div class="w-300 mx-auto px-5">
          <div class="p-5 px-8 flex justify-center gap-x-8">
            <div
              class="bg-dokBlue-light w-8 h-8 rounded-full animate-bounce blue-circle"
            ></div>
            <div
              class="bg-rosDok-ultra w-8 h-8 rounded-full animate-bounce red-circle"
            ></div>
            <div
              class="bg-dokBlue-ultra w-8 h-8 rounded-full animate-bounce green-circle"
            ></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// Componnets
import card from "../layouts/card.vue";
// Event Bus
import { EventBus } from "@/event-bus";

export default {
  components: { card },
  data() {
    return {
      data: [],
      loader: true
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    EventBus.$on("MY_NETWORK_EMIT_SEARCH", data => this.onSearch(data));
  },
  methods: {
    emitInviter() {
      EventBus.$emit("CHANGE_PAGE_SOCIAL_NETWORK", 3);
    },
    emitInviterModal() {
      EventBus.$emit("MODAL_INVITER_CONFRERE");
    },
    onSearch(data) {
      console.log(data);
    },
    async getList() {
      this.loader = true;
      await this.$store.dispatch("social/LIST_INVITATIONS", {
        onData: ({ ok, data }) => {
          if (ok) {
            this.data = data;

            this.$nextTick(() => {
              this.loader = false;
            });
          }
        }
      });
    }
  }
};
</script>
