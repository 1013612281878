<template>
  <div
    v-if="ownerUser"
    class="bg-white hover:shadow-card border-2 border-solid border-card-border w-full lg:w-cardNetwork rounded-lg p-4 flex flex-col gap-y-5"
  >
    <!-- User Informations -->
    <template v-if="ownerUser">
      <div class="flex-1 flex flex-col gap-y-3">
        <!-- Name & Avatar -->
        <div class="flex flex-col gap-y-2">
          <!-- Doctor Image Profile -->
          <div class="relative h-181 overflow-hidden">
            <div
              class="absolute inset-0  flex flex-col bg-opacity-10 rounded"
              :class="userDropDown ? 'bg-black' : 'bg-transparent'"
              v-if="data.status === 'ACCEPTED'"
            >
              <span class="flex justify-end p-2">
                <dok-button
                  bg="action"
                  custom-class="m-0 p-0"
                  @click.native="userDropDown = !userDropDown"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-more-vertical"
                  >
                    <circle cx="12" cy="12" r="1"></circle>
                    <circle cx="12" cy="5" r="1"></circle>
                    <circle cx="12" cy="19" r="1"></circle>
                  </svg>
                </dok-button>
              </span>
              <div class="w-[95%] mx-auto" v-if="userDropDown">
                <div class="bg-white w-full rounded p-5 flex flex-col gap-y-2">
                  <span
                    class="text-[#000000] text-lg font-EffraR flex items-center gap-x-2 cursor-pointer"
                    @click="onClickPreviewProfile"
                  >
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                        stroke="#333638"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                        stroke="#333638"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    Voir Profil
                  </span>
                  <span
                    class="text-[#d74824] text-lg font-EffraR flex items-center gap-x-2 cursor-pointer"
                    @click="changeStatus('DELETE')"
                  >
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 6h18M8 6V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m3 0v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6h14zM10 11v6M14 11v6"
                        stroke="#D74824"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    Supprimer
                  </span>
                </div>
              </div>
            </div>
            <img
              :src="$checkAvatar(ownerUser.image)"
              class="w-full h-181 object-cover rounded"
            />
          </div>
          <!-- Full Name -->
          <h1
            class="text-black text-base font-bold font-EffraR"
            style="line-height: 1.63;"
          >
            {{
              ownerUser
                ? `${ownerUser.title} . ${ownerUser.firstName} ${ownerUser.lastName}`
                : "--"
            }}
          </h1>
        </div>

        <!-- Other Informations -->
        <div class="flex flex-col gap-y-1">
          <!-- Specialites  -->
          <div
            class="font-EffraR text-base text-black leading-loose flex items-start gap-x-2"
          >
            <div class="w-[20px] h-[20px] mt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 28 28"
                class="h-6 w-6"
              >
                <path
                  fill="#111"
                  d="M10 5v2H8v4c0 2.21 1.79 4 4 4s4-1.79 4-4V7h-2V5h3c.552 0 1 .448 1 1v5c0 2.973-2.162 5.44-5 5.917V18.5c0 1.933 1.567 3.5 3.5 3.5 1.497 0 2.775-.94 3.275-2.263C18.728 19.27 18 18.22 18 17c0-1.657 1.343-3 3-3s3 1.343 3 3c0 1.371-.92 2.527-2.176 2.885C21.21 22.252 19.059 24 16.5 24c-3.038 0-5.5-2.462-5.5-5.5v-1.583C8.162 16.441 6 13.973 6 11V6c0-.552.448-1 1-1h3zm11 11c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
                />
              </svg>
            </div>

            {{
              ownerUser.landingPage && ownerUser.landingPage.specialities
                ? ownerUser.landingPage.specialities[0].name
                : "--"
            }}
          </div>
          <!-- Experience  -->
          <div
            class="font-EffraR text-base text-black leading-loose flex items-center gap-x-2"
          >
            <div class="w-[20px] h-[20px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="h-6 w-6"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <polyline points="12 6 12 12 16 14"></polyline>
              </svg>
            </div>

            {{
              ownerUser.landingPage &&
              ownerUser.landingPage.experienceStartedSince
                ? formatExpirance(ownerUser.landingPage.experienceStartedSince)
                  ? `${formatExpirance(
                      ownerUser.landingPage.experienceStartedSince
                    )} ans d'expérience`
                  : "--"
                : "--"
            }}
          </div>
          <!-- City  -->
          <div
            class="font-EffraR text-base text-black leading-loose flex items-start gap-x-2"
          >
            <div class="w-[20px] h-[20px] mt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 24 24"
                class="h-6 w-6"
              >
                <path
                  fill="#111"
                  fill-rule="evenodd"
                  d="M12 2c-2.122 0-4.157.843-5.657 2.343S4 7.878 4 10c0 3.098 2.016 6.104 4.226 8.437 1.085 1.145 2.173 2.082 2.99 2.734.305.242.571.444.784.602.213-.158.479-.36.783-.602.818-.651 1.906-1.589 2.991-2.734C17.984 16.104 20 13.098 20 10c0-2.122-.843-4.157-2.343-5.657S14.122 2 12 2zm0 21l-.555.832-.003-.002-.007-.005-.023-.015-.082-.057c-.072-.05-.174-.12-.302-.214-.258-.186-.622-.457-1.057-.804-.87-.692-2.032-1.692-3.197-2.922C4.484 17.396 2 13.902 2 10c0-2.652 1.054-5.196 2.929-7.071C6.804 1.054 9.348 0 12 0s5.196 1.054 7.071 2.929C20.946 4.804 22 7.348 22 10c0 3.902-2.484 7.396-4.774 9.813-1.165 1.23-2.327 2.23-3.197 2.922-.435.347-.8.618-1.057.804-.128.093-.23.165-.302.214l-.082.057-.023.015-.007.005-.002.001s-.001.001-.556-.831zm0 0l.555.832c-.336.224-.774.224-1.11 0L12 23z"
                  clip-rule="evenodd"
                />
                <path
                  fill="#111"
                  fill-rule="evenodd"
                  d="M12 8c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm-4 2c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>

            {{
              ownerUser.landingPage &&
              ownerUser.landingPage.address &&
              ownerUser.landingPage.address.city
                ? `${ownerUser.landingPage.address.city.name}, ${ownerUser.landingPage.address.label}`
                : "--"
            }}
          </div>
        </div>
      </div>
    </template>

    <!-- Actions Default -->
    <template v-if="!data.status">
      <dok-button
        bg="trans"
        size="md"
        custom-class="font-EffraM flex items-center gap-x-1 hover:bg-dokBlue-ultra hover:text-white"
      >
        <svg
          width="19"
          height="18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.5 3.75v10.5M4.25 9h10.5"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        Ajouter à mon réseau
      </dok-button>
    </template>

    <!-- Actions Confrere -->
    <template v-if="data.status === 'ACCEPTED'">
      <dok-button
        bg="trans"
        size="md"
        custom-class="font-EffraM flex items-center justify-center hover:bg-dokBlue-ultra gap-x-1 group"
        @click.native="onClickTransferPatientToDoctor"
      >
        <span class="flex group-hover:text-white items-center gap-x-1">
          <svg
            width="18"
            height="18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.645 7.079a3.27 3.27 0 0 0-1.65-2.84c.3-.362.481-.828.481-1.335a2.101 2.101 0 0 0-2.099-2.1 2.101 2.101 0 0 0-2.099 2.1c0 .507.182.973.482 1.336a3.27 3.27 0 0 0-1.65 2.839V8.08c0 .329.266.596.596.596h5.343c.33 0 .596-.267.596-.596V7.079zm-3.268-5.082a.908.908 0 0 1 0 1.814.908.908 0 0 1 0-1.814zm-2.076 5.488v-.406c0-1.145.932-2.076 2.076-2.076 1.145 0 2.076.931 2.076 2.076v.406h-4.152zm-5.41.596V7.079a3.27 3.27 0 0 0-1.65-2.84c.3-.362.481-.828.481-1.335a2.101 2.101 0 0 0-2.099-2.1 2.101 2.101 0 0 0-2.099 2.1c0 .507.181.973.482 1.336a3.27 3.27 0 0 0-1.65 2.839V8.08c0 .329.266.596.595.596h5.344c.33 0 .596-.267.596-.596zM3.623 1.997a.908.908 0 0 1 0 1.814.908.908 0 0 1 0-1.814zM1.547 7.485v-.406c0-1.145.931-2.076 2.076-2.076s2.076.931 2.076 2.076v.406H1.547zM14.8 9.997a.596.596 0 0 0-.843 0l-2.004 2.004a.596.596 0 0 0 .843.843l.986-.987v3.31H4.22v-4.748a.596.596 0 1 0-1.192 0v5.343c0 .33.267.596.596.596h10.754c.33 0 .596-.267.596-.596v-3.905l.987.987a.596.596 0 0 0 .843-.843l-2.004-2.004z"
              fill="currentColor"
              stroke="currentColor"
              stroke-width=".19"
            />
          </svg>

          Adresser un patient
        </span>
      </dok-button>

      <!-- Modal Adresser un patient -->
      <modal
        :isActive="modal.is"
        :exitModal="
          () => {
            modal.is = false;
          }
        "
        :hidden-header="true"
      >
        <div class="py-5 pl-5 pr-2 flex flex-col gap-y-7">
          <div class="flex justify-between items-center">
            <h1 class="font-EffraR text-xl flex-1">
              Adresser un patient ({{
                `${ownerUser.title} . ${ownerUser.firstName} ${ownerUser.lastName}`
              }})
            </h1>
            <svg
              width="24"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="cursor-pointer"
              @click="
                () => {
                  modal.is = false;
                }
              "
            >
              <path
                d="M18 6 6 18M6 6l12 12"
                stroke="#111"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div class="flex flex-col gap-y-4">
            <dok-input
              label="Rechercher"
              d-placeholder="Trouver un patient"
              d-type="text"
              size="lg"
              :d-model.sync="modal.search"
            />

            <!-- List Patients -->
            <div class="max-h-[250px] overflow-y-scroll">
              <div
                class="mb-4 h-[70px] border border-solid border-[#d7dde5] rounded flex flex-row items-center gap-x-2 px-4"
                v-for="(pat, index) in modal.patients"
                :key="index"
              >
                <input
                  type="checkbox"
                  class="
                      form-checkbox
                      border-2 border-solid border-gray-400
                      text-dokBlue-ultra
                      rounded-none
                      text-2xl
                      focus:outline-none
                      focus:shadow-none"
                  :id="`listPatient_${pat._id}`"
                  :value="pat._id"
                  v-model="modal.selected"
                />
                <label
                  :for="`listPatient_${pat._id}`"
                  class="font-EffraR text-[16px] text-[#333638] leading-tight"
                >
                  {{ `${pat.firstName} ${pat.lastName}` }}
                </label>
              </div>
            </div>

            <!-- Actions -->
            <div>
              <dok-button
                bg="blue"
                size="lg"
                :custom-class="
                  `w-full px-3 text-white flex items-center justify-center gap-x-2 ${
                    !modal.selected.length ? 'bg-opacity-60' : ''
                  }`
                "
                @click.native="!modal.selected.length ? () => {} : onShare()"
              >
                <svg
                  width="24"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.495 9.438c0-1.62-.896-3.035-2.218-3.776.41-.482.66-1.108.66-1.79a2.77 2.77 0 0 0-2.767-2.767 2.77 2.77 0 0 0-2.767 2.767c0 .682.248 1.308.66 1.79a4.328 4.328 0 0 0-2.219 3.776v1.336c0 .422.342.763.763.763h7.125a.763.763 0 0 0 .763-.763V9.438zM19.17 2.631c.684 0 1.24.557 1.24 1.241s-.556 1.241-1.24 1.241c-.685 0-1.241-.557-1.241-1.24 0-.685.556-1.242 1.24-1.242zm-2.8 7.38v-.573c0-1.543 1.256-2.8 2.8-2.8 1.543.001 2.8 1.257 2.8 2.8v.573h-5.6zm-7.214.763V9.438c0-1.62-.896-3.035-2.218-3.776.41-.482.66-1.108.66-1.79A2.77 2.77 0 0 0 4.83 1.105a2.77 2.77 0 0 0-2.767 2.767c0 .682.248 1.308.66 1.79A4.328 4.328 0 0 0 .504 9.438v1.336c0 .422.342.763.763.763h7.125a.763.763 0 0 0 .763-.763zM4.831 2.631c.684 0 1.24.557 1.24 1.241s-.556 1.241-1.24 1.241c-.685 0-1.241-.557-1.241-1.24 0-.685.556-1.242 1.24-1.242zm2.8 7.38h-5.6v-.573c0-1.543 1.256-2.8 2.8-2.8 1.543.001 2.8 1.257 2.8 2.8v.573zm12.078 3.341a.763.763 0 0 0-1.079 0l-2.672 2.672a.763.763 0 0 0 1.08 1.079l1.369-1.37v4.52H5.594v-6.361a.763.763 0 1 0-1.526 0v7.125c0 .421.341.763.763.763H19.17a.763.763 0 0 0 .763-.763v-5.283l1.369 1.369a.763.763 0 1 0 1.079-1.079l-2.672-2.672z"
                    fill="#fff"
                    stroke="#fff"
                    stroke-width=".19"
                  />
                </svg>
                Partager
              </dok-button>
            </div>
          </div>
        </div>
      </modal>

      <!-- Modal Preview Profile -->
      <modal
        :isActive="modalPreview.is"
        :exitModal="
          () => {
            modalPreview.is = false;
          }
        "
        :hidden-header="true"
        :width="639"
      >
        <div class="py-5 pl-5 pr-2 flex flex-col gap-y-7">
          <div class="flex flex-row items-start">
            <div class="flex-1 flex flex-row gap-x-6">
              <div
                class="w-[172px] h-[172px] rounded-lg bg-dokBlue-ultra overflow-hidden"
              >
                <img
                  :src="$checkAvatar(modalPreview.data.image)"
                  class="h-full w-full object-cover"
                />
              </div>
              <div class="flex flex-col gap-y-6">
                <!-- full name  -->
                <h1
                  class="font-EffraM text-2xl font-medium leading-tight text-black"
                >
                  {{
                    `${modalPreview.data.title} ${modalPreview.data.firstName} ${modalPreview.data.lastName}`
                  }}
                </h1>
                <div class="flex flex-col gap-y-4">
                  <div class="grid grid-cols-2 gap-x-2 gap-y-4">
                    <!-- Specialty -->
                    <div class="flex flex-row items-start gap-x-2">
                      <svg
                        width="14"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.798 9.85c.422 0 1.044.158 1.612.913.276.366.428.803.44 1.262.021.797.112 3.392.416 4.664.89-.491 2.481-1.933 1.974-5.843a6.683 6.683 0 0 0-.371-1.43c-.172-.474-.213-1.248.568-2.245.277-.354.488-.692.644-1.034.365-.796.883-2.298.26-3.409-.376-.669-1.147-1.138-2.291-1.395a3.742 3.742 0 0 1-.143-.037c-.13.061-.473.261-1.142.89-.113.112-.66.61-1.507.61-.423 0-.838-.125-1.234-.371L4.16 1.337a.965.965 0 0 0-.394-.087.806.806 0 0 0-.438.129L3.29 1.4l-.04.019A4.292 4.292 0 0 0 1.93 2.46c-.697.849-.77 1.823-.22 2.895.084.164.156.312.22.451l.836 1.844c.072.148.377.878-.07 1.786a7.029 7.029 0 0 0-.672 2.849c-.026 1.738.682 3.213 2.103 4.385.237-.75.533-2.188.63-4.807.021-.717.426-1.371 1.083-1.745.312-.178.634-.268.958-.268zm2.362 8.105c-.53 0-.994-.323-1.127-.785-.368-1.273-.476-4.002-.506-5.117a1.077 1.077 0 0 0-.213-.623c-.186-.247-.374-.39-.516-.39a.56.56 0 0 0-.25.083.916.916 0 0 0-.467.775c-.104 2.794-.436 4.339-.696 5.144-.162.5-.662.838-1.245.838-.323 0-.634-.109-.874-.306C1.557 16.18.67 14.345.7 12.27a8.183 8.183 0 0 1 .786-3.315c.215-.438.09-.773.07-.824l-.012-.02V8.11l-.84-1.853a9.429 9.429 0 0 0-.196-.401C-.46 3.967.186 2.56.898 1.712A5.63 5.63 0 0 1 2.609.38c.355-.212.744-.32 1.158-.32.59 0 1.013.222 1.092.266l.009.005L6.77 1.443a.932.932 0 0 0 .487.162c.293 0 .516-.209.518-.21L7.8 1.37C9.01.234 9.558.088 9.9.088c.077 0 .152.009.224.026a5.4 5.4 0 0 1 .137.035l.11.029c1.534.344 2.594 1.022 3.15 2.013.819 1.458.334 3.197-.216 4.4a6.298 6.298 0 0 1-.786 1.266c-.49.625-.456 1.013-.392 1.19.226.621.362 1.15.428 1.661.639 4.932-1.789 6.63-2.862 7.13a1.27 1.27 0 0 1-.533.117z"
                          fill="#2C292D"
                          stroke-width="2"
                        />
                      </svg>

                      <div class="flex flex-col">
                        <span class="font-EffraR font-medium text-base">
                          {{
                            modalPreview.data.landingPage &&
                            modalPreview.data.landingPage.specialities[0]
                              ? modalPreview.data.landingPage.specialities[0]
                                  .name
                              : "--"
                          }}
                        </span>
                        <span
                          class="text-sm font-normal font-EffraR text-[#767676]"
                        >
                          Specialty
                        </span>
                      </div>
                    </div>

                    <!-- Fix Number -->
                    <div class="flex flex-row items-start gap-x-2">
                      <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 12.69v2.25a1.502 1.502 0 0 1-1.635 1.5 14.843 14.843 0 0 1-6.472-2.302 14.625 14.625 0 0 1-4.5-4.5A14.843 14.843 0 0 1 1.59 3.135 1.5 1.5 0 0 1 3.083 1.5h2.25a1.5 1.5 0 0 1 1.5 1.29 9.63 9.63 0 0 0 .525 2.108A1.5 1.5 0 0 1 7.02 6.48l-.952.953a12 12 0 0 0 4.5 4.5l.952-.953a1.5 1.5 0 0 1 1.583-.337c.68.253 1.387.43 2.107.525a1.5 1.5 0 0 1 1.29 1.522z"
                          stroke="#353535"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <div class="flex flex-col">
                        <span class="font-EffraR font-medium text-base">
                          {{ modalPreview.data.fix }}
                        </span>
                        <span
                          class="text-sm font-normal font-EffraR text-[#767676]"
                        >
                          Fix
                        </span>
                      </div>
                    </div>

                    <!-- Experience -->
                    <div class="flex flex-row items-start gap-x-2">
                      <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 12.69v2.25a1.502 1.502 0 0 1-1.635 1.5 14.843 14.843 0 0 1-6.472-2.302 14.625 14.625 0 0 1-4.5-4.5A14.843 14.843 0 0 1 1.59 3.135 1.5 1.5 0 0 1 3.083 1.5h2.25a1.5 1.5 0 0 1 1.5 1.29 9.63 9.63 0 0 0 .525 2.108A1.5 1.5 0 0 1 7.02 6.48l-.952.953a12 12 0 0 0 4.5 4.5l.952-.953a1.5 1.5 0 0 1 1.583-.337c.68.253 1.387.43 2.107.525a1.5 1.5 0 0 1 1.29 1.522z"
                          stroke="#353535"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <div class="flex flex-col">
                        <span class="font-EffraR font-medium text-base">
                          {{
                            modalPreview.data.landingPage &&
                            modalPreview.data.landingPage.experienceStartedSince
                              ? formatExpirance(
                                  modalPreview.data.landingPage
                                    .experienceStartedSince
                                )
                              : "--"
                          }}
                        </span>
                        <span
                          class="text-sm font-normal font-EffraR text-[#767676]"
                        >
                          Experience
                        </span>
                      </div>
                    </div>

                    <!-- Ville -->
                    <div class="flex flex-row items-start gap-x-2">
                      <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9 1.5a6 6 0 0 0-6 6c0 2.324 1.512 4.578 3.17 6.328A22.065 22.065 0 0 0 9 16.329a22.065 22.065 0 0 0 2.83-2.502C13.489 12.079 15 9.825 15 7.5a6 6 0 0 0-6-6zm0 15.75-.416.624-.003-.002-.005-.003-.017-.012-.062-.042c-.053-.037-.13-.09-.226-.16a23.578 23.578 0 0 1-3.19-2.795C3.363 13.047 1.5 10.427 1.5 7.5a7.5 7.5 0 0 1 15 0c0 2.927-1.863 5.547-3.58 7.36a23.58 23.58 0 0 1-3.417 2.955l-.062.042-.017.012-.005.003-.002.001s-.001.001-.417-.623zm0 0 .416.624a.75.75 0 0 1-.832 0L9 17.25z"
                          fill="#111"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9 6a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM6 7.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0z"
                          fill="#111"
                        />
                      </svg>

                      <div class="flex flex-col">
                        <span class="font-EffraR font-medium text-base">
                          {{
                            modalPreview.data.landingPage &&
                            modalPreview.data.landingPage.address &&
                            modalPreview.data.landingPage.address.city
                              ? modalPreview.data.landingPage.address.city.name
                              : "--"
                          }}
                        </span>
                        <span
                          class="text-sm font-normal font-EffraR text-[#767676]"
                        >
                          Ville
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- CLOSE MODAL BUTTON -->
            <button
              class="bg-transparent border-0 cursor-pointer"
              @click="
                () => {
                  modalPreview.is = false;
                }
              "
            >
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6 6 18M6 6l12 12"
                  stroke="#111"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <!-- Address Parcours Diplomes Spoken -->
          <div class="flex flex-col gap-y-12">
            <!-- ACTIONS  -->
            <div class="flex flex-row justify-center gap-x-4">
              <dok-button
                bg="no"
                size="md"
                custom-class="flex items-center justify-center gap-x-1 px-8 w-full border-2 border-solid border-[#297afb] text-[#297afb] hover:bg-[#297afb] hover:text-white"
                @click.native="onClickTransferPatientToDoctor"
              >
                <svg
                  width="18"
                  height="18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.645 7.079a3.27 3.27 0 0 0-1.65-2.84c.3-.362.481-.828.481-1.335a2.101 2.101 0 0 0-2.099-2.1 2.101 2.101 0 0 0-2.099 2.1c0 .507.182.973.482 1.336a3.27 3.27 0 0 0-1.65 2.839V8.08c0 .329.266.596.596.596h5.343c.33 0 .596-.267.596-.596V7.079zm-3.268-5.082a.908.908 0 0 1 0 1.814.908.908 0 0 1 0-1.814zm-2.076 5.488v-.406c0-1.145.932-2.076 2.076-2.076 1.145 0 2.076.931 2.076 2.076v.406h-4.152zm-5.41.596V7.079a3.27 3.27 0 0 0-1.65-2.84c.3-.362.481-.828.481-1.335a2.101 2.101 0 0 0-2.099-2.1 2.101 2.101 0 0 0-2.099 2.1c0 .507.181.973.482 1.336a3.27 3.27 0 0 0-1.65 2.839V8.08c0 .329.266.596.595.596h5.344c.33 0 .596-.267.596-.596zM3.623 1.997a.908.908 0 0 1 0 1.814.908.908 0 0 1 0-1.814zM1.547 7.485v-.406c0-1.145.931-2.076 2.076-2.076s2.076.931 2.076 2.076v.406H1.547zM14.8 9.997a.596.596 0 0 0-.843 0l-2.004 2.004a.596.596 0 0 0 .843.843l.986-.987v3.31H4.22v-4.748a.596.596 0 1 0-1.192 0v5.343c0 .33.267.596.596.596h10.754c.33 0 .596-.267.596-.596v-3.905l.987.987a.596.596 0 0 0 .843-.843l-2.004-2.004z"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width=".19"
                  />
                </svg>

                Adresser un patient
              </dok-button>
              <dok-button
                bg="no"
                size="md"
                custom-class="flex items-center justify-center gap-x-1 px-8 w-full border-2 border-solid border-[#d74824] text-[#d74824] hover:bg-[#d74824] hover:text-white"
                @click.native="changeStatus('DELETE')"
              >
                <svg
                  width="18"
                  height="18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2.25 4.5h13.5" fill="currentColor" />
                  <path
                    d="M2.25 4.5h13.5M6 4.5V3a1.5 1.5 0 0 1 1.5-1.5h3A1.5 1.5 0 0 1 12 3v1.5m2.25 0V15a1.5 1.5 0 0 1-1.5 1.5h-7.5a1.5 1.5 0 0 1-1.5-1.5V4.5h10.5zM7.5 8.25v4.5M10.5 8.25v4.5"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                Supprimer confrére
              </dok-button>
            </div>

            <div id="otherInformation" class="grid grid-cols-2 gap-y-9">
              <!-- Address -->
              <div class="flex flex-row items-start gap-x-2 col-span-2">
                <div class="w-[18px] h-[18px]">
                  <svg
                    width="18"
                    height="18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9 1.5a6 6 0 0 0-6 6c0 2.324 1.512 4.578 3.17 6.328A22.065 22.065 0 0 0 9 16.329a22.065 22.065 0 0 0 2.83-2.502C13.489 12.079 15 9.825 15 7.5a6 6 0 0 0-6-6zm0 15.75-.416.624-.003-.002-.005-.003-.017-.012-.062-.042c-.053-.037-.13-.09-.226-.16a23.578 23.578 0 0 1-3.19-2.795C3.363 13.047 1.5 10.427 1.5 7.5a7.5 7.5 0 0 1 15 0c0 2.927-1.863 5.547-3.58 7.36a23.58 23.58 0 0 1-3.417 2.955l-.062.042-.017.012-.005.003-.002.001s-.001.001-.417-.623zm0 0 .416.624a.75.75 0 0 1-.832 0L9 17.25z"
                      fill="#111"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9 6a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM6 7.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0z"
                      fill="#111"
                    />
                  </svg>
                </div>

                <div class="flex flex-col gap-y-1">
                  <span class="font-EffraM font-medium text-lg text-[#6d6d6d]">
                    Address
                  </span>
                  <span class="text-base font-normal font-EffraM text-black">
                    {{
                      modalPreview.data.landingPage &&
                      modalPreview.data.landingPage.address
                        ? `${modalPreview.data.landingPage.address.label}, ${modalPreview.data.landingPage.address.country}`
                        : "--"
                    }}
                  </span>
                </div>
              </div>
              <!-- Parcours -->
              <div class="flex flex-row items-start gap-x-2">
                <div class="w-[18px] h-[18px]">
                  <svg
                    width="18"
                    height="18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 5.25H3a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h12a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5z"
                      stroke="#767676"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 15.75v-12a1.5 1.5 0 0 0-1.5-1.5h-3A1.5 1.5 0 0 0 6 3.75v12"
                      stroke="#767676"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div class="flex flex-col gap-y-1">
                  <span class="font-EffraM font-medium text-lg text-[#6d6d6d]">
                    Parcours Professionnel
                  </span>
                  <span class="text-base font-normal font-EffraM text-black">
                    {{ modalPreview.data.job || "--" }}
                  </span>
                </div>
              </div>
              <!-- Diplomes -->
              <div class="flex flex-row items-start gap-x-2">
                <div class="w-[18px] h-[18px]">
                  <svg
                    width="18"
                    height="18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 14.625a1.875 1.875 0 0 1 1.875-1.875H15"
                      stroke="#767676"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.875 1.5H15v15H4.875A1.875 1.875 0 0 1 3 14.625V3.375A1.875 1.875 0 0 1 4.875 1.5v0z"
                      stroke="#767676"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div class="flex flex-col gap-y-1">
                  <span class="font-EffraM font-medium text-lg text-[#6d6d6d]">
                    Diplomes
                  </span>
                  <span class="text-base font-normal font-EffraM text-black">
                    {{
                      modalPreview.data.landingPage &&
                      modalPreview.data.landingPage.experienceDiplomes
                        ? modalPreview.data.landingPage.experienceDiplomes
                        : "--"
                    }}
                  </span>
                </div>
              </div>
              <!-- Spoken Lang -->
              <div class="flex flex-row items-start gap-x-2">
                <div class="w-[18px] h-[18px]">
                  <svg
                    width="18"
                    height="18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 11.25s.75-.75 3-.75S9.75 12 12 12s3-.75 3-.75v-9S14.25 3 12 3 8.25 1.5 6 1.5s-3 .75-3 .75v9zM3 16.5v-5.25"
                      stroke="#767676"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div class="flex flex-col gap-y-1">
                  <span class="font-EffraM font-medium text-lg text-[#6d6d6d]">
                    Spoken Languages
                  </span>
                  <span class="text-base font-normal font-EffraM text-black">
                    {{
                      modalPreview.data.landingPage &&
                      modalPreview.data.landingPage.languages.length
                        ? modalPreview.data.landingPage.languages.join(", ")
                        : "--"
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
    </template>

    <!-- Actions Pending -->
    <template v-if="data.status === 'PENDING' && type === 'envoye'">
      <dok-button
        bg="yellow"
        size="md"
        custom-class="font-EffraM flex items-center justify-center hover:text-red-700 hover:border-red-700 gap-x-1 group"
        @click.native="changeStatus('CANCELED')"
      >
        <span class="flex group-hover:hidden items-center gap-x-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 24 24"
            class="animate-spin"
          >
            <path
              stroke="#AE851A"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
              d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"
            />
          </svg>
          En attente...
        </span>
        <span class="hidden group-hover:flex items-center gap-x-1">
          <svg
            width="18"
            height="18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m13.5 4.5-9 9M4.5 4.5l9 9"
              stroke="#BC2626"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Annuler...
        </span>
      </dok-button>
    </template>

    <!-- Actions Recevied-->
    <template v-if="data.status === 'PENDING' && type === 'recues'">
      <div class="flex flex-row gap-x-2">
        <dok-button
          bg="trans_red"
          size="md"
          custom-class="flex items-center justify-center"
          @click.native="changeStatus('REJECTED')"
        >
          <svg
            width="18"
            height="18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m13.5 4.5-9 9M4.5 4.5l9 9"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </dok-button>
        <dok-button
          bg="trans"
          size="md"
          custom-class="font-EffraM hover:bg-dokBlue-ultra hover:text-white w-full flex justify-center items-center"
          @click.native="changeStatus('ACCEPTED')"
        >
          <span class="flex items-center gap-x-1">
            <svg
              width="18"
              height="18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m15 4.5-8.25 8.25L3 9"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Accepter
          </span>
        </dok-button>
      </div>
    </template>
  </div>
</template>

<script>
// Components
const modal = () => import("@/views/global-components/modal.vue");

import { cloneDeep } from "lodash";
import moment from "moment";

// Event Bus
import { EventBus } from "@/event-bus";

export default {
  components: { modal },
  props: {
    data: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      requierd: true
    }
  },
  data() {
    return {
      modal: {
        is: false,
        dataTemp: [],
        patients: [],
        selected: [],
        count_patients: 0,
        search: null
      },
      modalPreview: {
        is: false,
        data: {}
      },
      userDropDown: false
    };
  },
  watch: {
    "modal.search"(newVal, oldVal) {
      if (newVal.length < 1) {
        return (this.modal.patients = this.modal.dataTemp);
      }

      if (newVal.length > 2) {
        return this.getListPatients();
      }
    }
  },
  computed: {
    ownerUser() {
      return this.data.recipientId &&
        this.data.recipientId._id === this.$auth.getUserInfo()._id
        ? this.data.senderId
        : this.data.recipientId;
    }
  },
  methods: {
    formatExpirance(dateString) {
      return moment().diff(dateString, "years", false);
    },
    onClickTransferPatientToDoctor() {
      this.$vs.loading();
      this.modalPreview = {
        is: false,
        data: {}
      };
      this.getListPatients();
    },
    async onClickPreviewProfile() {
      this.$vs.loading();
      await this.$store.dispatch("doctor/GET_BY_ID", {
        id: this.ownerUser._id,
        onData: ({ ok, data }) => {
          if (ok) {
            this.modalPreview.data = data;
            this.userDropDown = false;
            this.$nextTick(() => {
              this.modalPreview.is = true;
            });
          }

          this.$vs.loading.close();
        }
      });
    },
    async changeStatus(status) {
      // DELETE FUNCTION HERE.
      if (status === "DELETE") {
        alert("ARE U SUR !");
        return;
      }

      // OTHER STATUS
      await this.$store.dispatch("social/CHANGE_STATUS", {
        id: this.data._id,
        status: status,
        onData: ({ ok, data }) => {
          if (ok) {
            EventBus.$emit("REFRESH_LIST_INVITATIONS");
          }
        }
      });
    },
    async getListPatients() {
      await this.$store.dispatch("client/GET_LIST", {
        onData: ({ ok, data, count }) => {
          if (ok) {
            if (!this.modal.dataTemp.length) {
              this.modal.dataTemp = cloneDeep(data);
            }

            this.modal.patients = data;
            this.modal.count_patients = count;

            this.$nextTick(() => {
              this.modal.is = true;
              this.$vs.loading.close();
            });

            return;
          }
          this.$vs.loading.close();
        },
        search: {
          term: this.modal.search
        }
      });
    },
    async onShare() {
      this.modal.is = false;

      this.$nextTick(() => {
        EventBus.$emit("flashDok", {
          type: "success",
          message: "partagée avec succès"
        });
      });
    }
  }
};
</script>
