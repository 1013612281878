<template>
  <div class="bg-white rounded-lg p-6 flex flex-col gap-y-6">
    <template v-if="!loader">
      <!-- Header -->
      <nav class="flex flex-row gap-x-8">
        <span
          v-for="(tab, index) in tabsInvitation"
          :key="index"
          class="cursor-pointer py-4 flex items-center gap-x-2 border-0 border-b-2 border-solid"
          :class="
            tab.id === currentTab
              ? ' border-dokBlue-ultra text-dokBlue-ultra font-EffraM'
              : 'text-labelGray font-EffraR border-white'
          "
          @click="currentTab = tab.id"
        >
          <span v-html="tab.icon"></span>
          {{ tab.name }}
        </span>
      </nav>
      <!-- Recues -->
      <template v-if="currentTab === 1">
        <!-- if null Recues -->
        <div
          class="w-full flex flex-col gap-y-8 items-center justify-center"
          v-if="!data.length"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="98"
            height="98"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="text-dokBlue-ultra"
          >
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          </svg>

          <div class="flex flex-col w-full items-center justify-center gap-y-3">
            <h1 class="font-EffraM text-2xl text-black">
              Vous n'avez pas reçu d'invitations
            </h1>

            <p class="text-center font-EffraR text-base">
              Le réseau de confrères dok vous permet de créer un réseau connecté
              afin de faciliter aux <br />
              praticiens l'adressage des patients.
            </p>
          </div>

          <dok-button
            @click.native="emitPage(3)"
            size="lg"
            custom-class="px-10 font-EffraM flex items-center gap-x-2"
          >
            <svg
              width="18"
              height="18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.75v10.5M3.75 9h10.5"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Ajouter un confrère
          </dok-button>
        </div>
        <!-- list -->
        <div class="flex flex-wrap gap-x-5 gap-y-4" v-if="data.length">
          <card
            v-for="(card, index) in data"
            :key="index"
            :data="card"
            type="recues"
          />
        </div>
      </template>
      <!-- Envoyess -->
      <template v-if="currentTab === 2">
        <!-- if null Envoyées -->
        <div
          v-if="!data.length"
          class="w-full flex flex-col gap-y-8 items-center justify-center"
        >
          <svg
            width="98"
            height="98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M89.833 8.167 44.917 53.084M89.833 8.167 61.25 89.834l-16.333-36.75L8.167 36.75 89.833 8.167z"
              stroke="#297AFB"
              stroke-width="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="flex flex-col w-full items-center justify-center gap-y-3">
            <h1 class="font-EffraM text-2xl text-black">
              Vous n'avez pas envoyé d'invitations
            </h1>

            <p class="text-center font-EffraR text-base">
              Le réseau d'adressage Dok.ma permet à vos confrères d'avoir accès
              à vos disponibilités. <br />
              Ils pourront ainsi prendre rendez-vous et vous adresser leurs
              patients de manière simple. <br />
              Ce service est gratuit.
            </p>
          </div>

          <dok-button
            @click.native="emitPage(3)"
            size="lg"
            custom-class="px-10 font-EffraM flex items-center gap-x-2"
          >
            <svg
              width="18"
              height="18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.75v10.5M3.75 9h10.5"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Ajouter un confrère
          </dok-button>
        </div>
        <!-- list -->
        <div class="flex flex-wrap gap-x-5 gap-y-4" v-if="data.length">
          <card
            v-for="(card, index) in data"
            :key="index"
            :data="card"
            type="envoye"
          />
        </div>
      </template>
    </template>
    <template v-else>
      <div class="w-300 mx-auto px-5">
        <div class="p-5 px-8 flex justify-center gap-x-8">
          <div
            class="bg-dokBlue-light w-8 h-8 rounded-full animate-bounce blue-circle"
          ></div>
          <div
            class="bg-rosDok-ultra w-8 h-8 rounded-full animate-bounce red-circle"
          ></div>
          <div
            class="bg-dokBlue-ultra w-8 h-8 rounded-full animate-bounce green-circle"
          ></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// Components
import card from "../layouts/card.vue";

// Event Bus
import { EventBus } from "@/event-bus";

export default {
  components: {
    card
  },
  data() {
    return {
      loader: true,
      currentTab: 1,
      tabsInvitation: [
        {
          name: "Reçues",
          icon: `
            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#a)" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M11.333 14v-1.333A2.667 2.667 0 0 0 8.667 10H3.333a2.667 2.667 0 0 0-2.666 2.667V14M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333zM15.333 14v-1.333a2.667 2.667 0 0 0-2-2.58M10.667 2.087a2.667 2.667 0 0 1 0 5.167"/>
                </g>
                <defs>
                    <clipPath id="a">
                        <path fill="#fff" d="M0 0h16v16H0z"/>
                    </clipPath>
                </defs>
            </svg>
          `,
          id: 1
        },
        {
          name: "Envoyées",
          icon: `
          <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.667 1.333 7.333 8.666M14.667 1.333 10 14.666l-2.667-6-6-2.666 13.334-4.667z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          `,
          id: 2
        }
      ],
      data: []
    };
  },
  watch: {
    currentTab(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getList();
      }
    }
  },
  mounted() {
    EventBus.$on("REFRESH_LIST_INVITATIONS", () => {
      this.getList();
    });
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loader = true;
      await this.$store.dispatch("social/LIST_INVITATIONS", {
        data: {
          type: this.currentTab === 2 ? "sent" : "recieved"
        },
        onData: ({ ok, data }) => {
          if (ok) {
            this.data = data;
            this.$nextTick(() => {
              this.loader = false;
            });
          }
        }
      });
    },
    emitPage(number) {
      EventBus.$emit("CHANGE_PAGE_SOCIAL_NETWORK", number);
    }
  }
};
</script>
