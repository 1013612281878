<template>
  <div class="w-full bg-white rounded-lg flex flex-col gap-y-0">
    <!-- Header -->
    <div
      class="h-[105px] flex flex-row gap-x-4 items-center px-8 border-0 border-b border-solid border-gray-200"
    >
      <!-- Avatar -->
      <div>
        <div
          class="h-[48px] w-[48px] bg-black rounded-full text-white flex items-center justify-center font-EffraM"
        >
          NA
        </div>
      </div>
      <!-- Username & Email -->
      <div class="flex flex-col">
        <h5 class="text-xl text-black font-EffraM font-medium">
          Abderrahman Nazih
        </h5>
        <span class="text-[#868c92] font-EffraR text-base">g8k@hotmail.fr</span>
      </div>
    </div>
    <!-- Content -->
    <div
      class="flex-1 max-h-[450px] overflow-hidden overflow-y-auto flex flex-col gap-y-4 py-6"
    >
      <div
        v-for="(msg, index) in Array(60).fill(
          {
            from: true
          },
          0,
          3
        )"
        :key="index"
      >
        <template v-if="msg && msg.from">
          <div class="w-full px-8 flex justify-end">
            <div
              class="bg-dokBlue-lighter py-4 px-5 rounded-lg text-black text-base font-EffraR"
            >
              Hello Nazih
            </div>
          </div>
        </template>
        <template v-else>
          <div class="w-full px-8 flex justify-start">
            <div
              class="bg-dokBlue-lighterrr py-4 px-5 rounded-lg text-black text-base font-EffraR"
            >
              Hello !! how are u ?
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- Form -->
    <div
      class="h-[112px] flex flex-row items-center gap-x-6 px-8 border-0 border-t border-solid border-gray-200"
    >
      <button type="button" class="border-0 bg-transparent p-0 m-0 relative">
        <input
          type="file"
          class="h-full w-full absolute opacity-0 cursor-pointer"
        />
        <svg
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m14.828 7.757-5.656 5.657a1 1 0 1 0 1.414 1.414l5.657-5.656A3 3 0 1 0 12 4.929l-5.657 5.657a5 5 0 1 0 7.071 7.07L19.071 12l1.414 1.414-5.657 5.657a6.999 6.999 0 0 1-11.95-4.95 7 7 0 0 1 2.05-4.95l5.658-5.656a5 5 0 0 1 7.07 7.07L12 16.244a2.998 2.998 0 0 1-4.243 0 3.001 3.001 0 0 1 0-4.244l5.657-5.657 1.414 1.414z"
            fill="#333638"
          />
        </svg>
      </button>
      <input
        type="text"
        class="flex-1 h-[48px] rounded-r-lg border border-solid border-[#d7dde5] px-3"
      />
      <dok-button
        bg="blue"
        size="lg"
        custom-class="flex items-center gap-x-2 px-8"
      >
        <svg
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 2 11 13M22 2l-7 20-4-9-9-4 20-7z"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        Envoyer
      </dok-button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>