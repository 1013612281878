<template>
  <div class="w-full bg-white rounded-lg flex flex-col gap-y-0">
    <!-- HEADER -->
    <header
      class="h-[105px] flex flex-row gap-x-4 items-center px-8 border-0 border-b border-solid border-gray-200"
    >
      <!-- IF NOT OPEN MESSAGE  -->

      <h1
        class="text-2xl font-EffraM text-dokBlue-lighterr"
        v-if="!isOpenMessage"
      >
        {{ type === "INBOX" ? "List Inbox" : "List Sent" }}
      </h1>

      <!-- IF OPEN MESSAGE  -->
      <template v-if="isOpenMessage">
        <button
          class="border-0 bg-transparent cursor-pointer hover:text-dokBlue-ultra"
          @click="isOpenMessage = false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-corner-up-left"
          >
            <polyline points="9 14 4 9 9 4"></polyline>
            <path d="M20 20v-7a4 4 0 0 0-4-4H4"></path>
          </svg>
        </button>
        <h1 class="font-EffraR">
          Help us protect you: Security advice from dok
        </h1>
      </template>
    </header>
    <!-- CONTENT -->
    <main
      class="flex-1 max-h-[450px] overflow-hidden overflow-y-auto divide-x-0 divide-y divide-solid divide-gray-200"
    >
      <!-- IF NOT OPEN MESSAGE  -->
      <template v-if="!isOpenMessage">
        <div
          v-for="(msg, index) in Array(30)"
          :key="index"
          @click="isOpenMessage = true"
          class="bg-white flex gap-x-2 items-center font-EffraR px-2 hover:bg-dokBlue-lighter cursor-pointer h-16"
        >
          <input type="checkbox" /> Help us protect you: Security advice from
          dok
        </div>
      </template>
      <!-- IF OPEN MESSAGE  -->
      <template v-if="isOpenMessage">
        <div class="p-5 font-EffraR text-lg leading-relaxed">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          <br />
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          <br />
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </div>
      </template>
    </main>
    <!-- FOOTER -->
    <div
      v-if="isOpenMessage"
      class="h-[112px] flex flex-row items-center gap-x-6 px-8 border-0 border-t border-solid border-gray-200"
    >
      <button type="button" class="border-0 bg-transparent p-0 m-0 relative">
        <input
          type="file"
          class="h-full w-full absolute opacity-0 cursor-pointer"
        />
        <svg
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m14.828 7.757-5.656 5.657a1 1 0 1 0 1.414 1.414l5.657-5.656A3 3 0 1 0 12 4.929l-5.657 5.657a5 5 0 1 0 7.071 7.07L19.071 12l1.414 1.414-5.657 5.657a6.999 6.999 0 0 1-11.95-4.95 7 7 0 0 1 2.05-4.95l5.658-5.656a5 5 0 0 1 7.07 7.07L12 16.244a2.998 2.998 0 0 1-4.243 0 3.001 3.001 0 0 1 0-4.244l5.657-5.657 1.414 1.414z"
            fill="#333638"
          />
        </svg>
      </button>
      <input
        type="text"
        class="flex-1 h-[48px] rounded-r-lg border border-solid border-[#d7dde5] px-3"
      />
      <dok-button
        bg="blue"
        size="lg"
        custom-class="flex items-center gap-x-2 px-8"
      >
        <svg
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 2 11 13M22 2l-7 20-4-9-9-4 20-7z"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        Envoyer
      </dok-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOpenMessage: false
    };
  },
  watch: {
    type(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isOpenMessage = false;
      }
    }
  }
};
</script>

<style>
</style>