<template>
  <div class="py-[32px] px-[42px] flex flex-row items-start gap-x-6">
    <!-------------------------------------------------- LEFT BLOCK -------------------------------------------------->
    <div class="w-[400px] min-w-[400px] max-w-[400px] bg-white rounded-lg">
      <!-- HEADER -->
      <div class="h-[105px] grid grid-cols-2 gap-x-2 items-center px-8">
        <button
          class="cursor-pointer border-0 bg-transparent text-xl font-EffraM font-medium flex items-center justify-center gap-x-2 whitespace-nowrap w-full"
          :class="currentNav === 'emails' ? 'text-black' : 'text-[#a8afb6]'"
          @click="currentNav = 'emails'"
        >
          Boit email
          <div>
            <div
              class="h-6 w-6 rounded-full bg-rosDok-ultra font-EffraR text-lg text-white flex items-center justify-center"
            >
              5
            </div>
          </div>
        </button>
        <button
          class="cursor-pointer border-0 bg-transparent text-xl font-EffraM font-medium flex items-center justify-center gap-x-2 whitespace-nowrap w-full"
          :class="currentNav === 'chat' ? 'text-black' : 'text-[#a8afb6]'"
          @click="currentNav = 'chat'"
        >
          Chat
          <div>
            <div
              class="h-6 w-6 rounded-full bg-rosDok-ultra font-EffraR text-lg text-white flex items-center justify-center"
            >
              5
            </div>
          </div>
        </button>
      </div>
      <!-- IF CURRENT TAB IS CHAT -->
      <doctor-list v-if="currentNav === 'chat'" />
      <!-- IF CURRENT TAB IS EMAILS -->
      <div v-if="currentNav === 'emails'" class="flex flex-col">
        <!-- INBOX TAB -->
        <div
          class="h-[64px] px-8 flex items-center justify-between cursor-pointer"
          :class="
            emailCurrentNav === 'INBOX'
              ? 'bg-dokBlue-lighter border-0 border-l-4 border-solid border-dokBlue-ultra font-EffraM'
              : 'hover:bg-dokBlue-lighter font-EffraR'
          "
          @click="emailCurrentNav = 'INBOX'"
        >
          INBOX
        </div>
        <!------------ LINE BETWEEN ------------>
        <div class="h-px border-0 border-b border-solid border-gray-200"></div>
        <!------------ END LINE BETWEEN ------------>
        <!-- SENT TAB -->
        <div
          class="h-[64px] px-8 flex items-center justify-between cursor-pointer"
          :class="
            emailCurrentNav === 'SENT'
              ? 'bg-dokBlue-lighter border-0 border-l-4 border-solid border-dokBlue-ultra font-EffraM'
              : 'hover:bg-dokBlue-lighter font-EffraR'
          "
          @click="emailCurrentNav = 'SENT'"
        >
          SENT
        </div>
      </div>
    </div>
    <!-------------------------------------------------- RIGHT BLOCK -------------------------------------------------->
    <!-- IF CURRENT TAB IS CHAT -->
    <content-chat v-if="currentNav === 'chat'" />
    <!-- IF CURRENT TAB IS EMAILS -->
    <emails-list v-if="currentNav === 'emails'" :type="emailCurrentNav" />
  </div>
</template>

<script>
// Messages
import doctorList from "./layouts/doctorsList.vue";
import contentChat from "./layouts/contentChat.vue";
// Emails
import emailsList from "./layouts/emailsList.vue";

export default {
  data() {
    return {
      currentNav: "emails",
      emailCurrentNav: "INBOX"
    };
  },
  components: {
    doctorList,
    contentChat,
    emailsList
  }
};
</script>

<style>
</style>