<template>
  <div
    class="bg-white rounded-lg p-6 grid grid-cols-1 lg:grid-cols-4 gap-x-3 gap-y-4 items-end"
  >
    <!--- Nom ---->
    <dok-input
      size="lg"
      d-placeholder="Nom et prénom du praticien..."
      d-type="text"
      label="Nom"
      :d-model.sync="tirm.name"
    >
      <template v-slot:leftIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-search"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
      </template>
    </dok-input>
    <!-- Ville -->
    <dok-select
      size="lg"
      custom-class="w-full"
      label="Ville"
      :d-model.sync="tirm.city"
    >
      <template v-slot:options>
        <option
          v-for="(city, index) in citys"
          :key="index"
          :value="city.name"
          >{{ city.name }}</option
        >
      </template>
      <template v-slot:rightIcon>
        <img src="/svg/chevron-down .svg" />
      </template>
      <template v-slot:leftIcon>
        <svg
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 2a8 8 0 0 0-8 8c0 3.098 2.016 6.104 4.226 8.437A29.419 29.419 0 0 0 12 21.773a29.412 29.412 0 0 0 3.774-3.335C17.984 16.103 20 13.097 20 10a8 8 0 0 0-8-8zm0 21-.555.832-.003-.002-.007-.005-.023-.015a28.518 28.518 0 0 1-1.441-1.074 31.428 31.428 0 0 1-3.197-2.923C4.484 17.396 2 13.902 2 10a10 10 0 0 1 20 0c0 3.902-2.484 7.396-4.774 9.813a31.433 31.433 0 0 1-4.254 3.726 18.887 18.887 0 0 1-.384.27l-.023.016-.007.005-.002.001s-.001.001-.556-.831zm0 0 .555.832a1 1 0 0 1-1.11 0L12 23z"
            fill="currentColor"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0z"
            fill="currentColor"
          />
        </svg>
      </template>
    </dok-select>
    <!-- Spécialité -->
    <div class="flex flex-col gap-y-1">
      <label for="Spécialité" class="font-EffraR text-base text-gray-800"
        >Spécialité</label
      >
      <specialitys
        custem-class="specialites-input"
        :no-multi="false"
        :city-choice.sync="tirm.specialite"
        :selected-items="[]"
        id="Spécialité"
      />
    </div>
    <!-- Actions -->
    <dok-button
      bg="blue"
      size="lg"
      custom-class="font-EffraM flex items-center justify-center gap-x-2"
      @click.native="searchEmite"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </svg>
      Rechercher
    </dok-button>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import { EventBus } from "@/event-bus";

// Components
const specialitys = () =>
  import("@/views/global-components/SpecialitiesSelect");

export default {
  props: {
    positionCard: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tirm: {},
      citys: [],
      algoliaKeys: algoliasearch(
        "2BJUOM1BDB",
        "7906f008c0346b78f39d051fa293387f"
      )
    };
  },
  created() {
    this.cities();
  },
  methods: {
    searchEmite() {
      if (this.positionCard === "search") {
        EventBus.$emit("SOCIAL_NETWORK_EMIT_SEARCH", this.tirm);
        return;
      }

      if (this.positionCard === "my_network") {
        EventBus.$emit("MY_NETWORK_EMIT_SEARCH", this.tirm);
        return;
      }
    },
    async cities() {
      const index = this.algoliaKeys.initIndex(
        "dokma_dev_cities_principal_first"
      );

      const data = await index.search("", {
        hitsPerPage: 618
      });

      this.citys = data.hits;
    }
  },
  components: {
    specialitys
  }
};
</script>

<style>
.specialites-input .multiselect__tag-icon:hover {
  background: #97b5ff !important;
  color: #2e6bff !important;
}

.specialites-input .multiselect__tag {
  background: #e4e4e4 !important;
  color: #2e6bff !important;
}

.specialites-input .multiselect__tags {
  min-height: 48px !important;
}

.specialites-input .multiselect__tags {
  border: 2px solid #e4e4e4 !important;
}

.specialites-input .multiselect__select {
  height: 45px !important;
}

.specialites-input .multiselect__placeholder {
  padding-top: 5px !important;
  @apply text-gray-600 text-lg;
}

.specialites-input .multiselect__input,
.specialites-input .multiselect__single {
  line-height: 30px;
  @apply font-EffraR;
}

.specialites-input .multiselect__option--highlight {
  @apply bg-dokBlue-light;
}
.specialites-input
  .multiselect__option--selected.multiselect__option--highlight {
  @apply bg-rosDok-light;
}
</style>
