<template>
  <div class="relative">
    <!-- Header -->
    <header
      class="bg-white fixed w-full px-10 flex flex-col justify-center z-2"
      style="height: 118px"
    >
      <!-- <span class="font-EffraR text-labelGray flex-1 pt-5"
        >Reseaux d’adressage</span
      > -->
      <nav class="flex flex-row gap-x-8">
        <span
          v-for="(tab, index) in tabs"
          :key="index"
          class="
            cursor-pointer
            py-4
            flex
            items-center
            gap-x-2
            border-0 border-b-2 border-solid
          "
          :class="
            tab.id === currentTab
              ? ' border-dokBlue-ultra text-dokBlue-ultra font-EffraM'
              : 'text-labelGray font-EffraR border-white'
          "
          @click="currentTab = tab.id"
        >
          <span v-html="tab.icon"></span>
          {{ tab.name }}
        </span>
      </nav>
    </header>

    <!-- content -->
    <main class="px-10 py-100 flex flex-col gap-y-3">
      <!-- Search Page  -->
      <template v-if="currentTab === 3">
        <header-search position-card="search" />
        <search />
      </template>

      <!-- Invitations -->
      <template v-if="currentTab === 2">
        <invitations />
      </template>

      <!-- Mon réseau -->
      <template v-if="currentTab === 1">
        <header-search position-card="my_network" />
        <my-network />
      </template>

      <!-- Chat  -->
      <template v-if="currentTab === 4">
        <chat />
      </template>
    </main>

    <modal
      :isActive="modal.is"
      :exitModal="() => {}"
      :min-width="400"
      :hidden-header="true"
    >
      <div class="p-5 flex flex-col gap-y-8">
        <template v-if="modal.type === 'INVITER_CONFERE'">
          <div class="flex justify-between items-center">
            <h1
              class="flex items-center gap-x-1 text-black text-2xl font-EffraR"
            >
              <svg
                width="18"
                height="18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 1.5 8.25 9.75M16.5 1.5l-5.25 15-3-6.75-6.75-3 15-5.25z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Inviter confrère
            </h1>
            <span
              class="cursor-pointer"
              @click="
                () => {
                  this.modal.is = false;
                  this.modal.type = '';
                }
              "
            >
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6 6 18M6 6l12 12"
                  stroke="#111"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>

          <sendRequest />
        </template>
      </div>
    </modal>
  </div>
</template>

<script>
// Components
import chat from "./chat/index.vue";
import sendRequest from "./layouts/sent_request.vue";
import headerSearch from "./layouts/header_search.vue";
import myNetwork from "./components/my_network.vue";
import invitations from "./components/invitations.vue";
import search from "./components/search.vue";
import modal from "@/views/global-components/modal";

// Event Bus
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      tabs: [
        {
          name: "Mon réseau",
          icon: `
            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.75 6.292c0-1.077-.576-2.02-1.435-2.542a1.92 1.92 0 0 0 .396-1.169A1.933 1.933 0 0 0 12.78.65a1.933 1.933 0 0 0-1.932 1.931c0 .44.148.845.396 1.17a2.971 2.971 0 0 0-1.435 2.541v.89c0 .33.267.596.596.596h4.75a.595.595 0 0 0 .595-.595v-.891zM12.78 1.84a.742.742 0 0 1 0 1.482.742.742 0 0 1 0-1.482zm1.78 4.747H11v-.295c0-.981.799-1.78 1.78-1.78.98 0 1.78.799 1.78 1.78v.295zm-8.37.596v-.891c0-1.077-.575-2.02-1.434-2.542.248-.324.396-.73.396-1.169A1.933 1.933 0 0 0 3.22.65 1.933 1.933 0 0 0 1.29 2.58c0 .44.147.845.395 1.17A2.971 2.971 0 0 0 .25 6.291v.89c0 .33.267.596.595.596h4.75a.595.595 0 0 0 .596-.595zM3.22 1.84a.742.742 0 0 1 0 1.482.742.742 0 0 1 0-1.482zM1.44 6.587v-.295c0-.981.8-1.78 1.78-1.78.981 0 1.78.799 1.78 1.78v.295H1.44zM13.2 8.84a.595.595 0 0 0-.841 0l-1.781 1.781a.595.595 0 1 0 .841.842l.765-.765v2.717H3.816V9.261a.595.595 0 0 0-1.191 0v4.75c0 .329.267.595.595.595h9.56a.595.595 0 0 0 .595-.595v-3.313l.765.765c.232.232.61.232.842 0 .232-.232.232-.61-.106-.736L13.2 8.84z" fill="currentColor" stroke="currentColor" stroke-width=".3"/>
            </svg>
          `,
          id: 1,
        },
        {
          name: "Invitations",
          icon: `
            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#a)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M10.667 14v-1.333A2.667 2.667 0 0 0 8 10H3.333a2.667 2.667 0 0 0-2.666 2.667V14M5.667 7.333a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333z" stroke-width="1.5"/>
                    <path d="M13.333 5.333v4M15.333 7.333h-4" stroke-width="2"/>
                </g>
                <defs>
                    <clipPath id="a">
                        <path fill="#fff" d="M0 0h16v16H0z"/>
                    </clipPath>
                </defs>
            </svg>
          `,
          id: 2,
        },
        {
          name: "Recherche",
          icon: `
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
          `,
          id: 3,
        },
      ],
      modal: {
        type: "",
        is: false,
      },
      currentTab: 1,
    };
  },
  mounted() {
    // Close Modal
    EventBus.$on("CLOSE_INVITER_CONFRERE", () => {
      this.modal = {
        type: "",
        is: false,
      };
    });
    // Open Modal
    EventBus.$on("MODAL_INVITER_CONFRERE", () => this.ON_CLICK_INVITE());
    // Change Pages
    EventBus.$on(
      "CHANGE_PAGE_SOCIAL_NETWORK",
      (number) => (this.currentTab = number)
    );
  },
  methods: {
    ON_CLICK_INVITE() {
      this.modal.type = "INVITER_CONFERE";
      this.$nextTick(() => {
        this.modal.is = true;
      });
      this.$performTracking({
        data: {
          id: this.$auth.getUserId(),
          route: "RESEAUX_ADRESSAGE",
          action: "NEW",
          data: {},
        },
      });
    },
  },
  components: {
    myNetwork,
    invitations,
    search,
    modal,
    chat,
    sendRequest,
    headerSearch,
  },
};
</script>
