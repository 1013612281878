<template>
  <div class="flex flex-col gap-y-6">
    <!-- Full Name -->
    <div class="grid grid-cols-2 gap-x-3">
      <dok-input
        size="lg"
        d-placeholder="Nom"
        d-type="text"
        label="Nom"
        :d-model.sync="data.firstName"
      >
      </dok-input>
      <dok-input
        size="lg"
        d-placeholder="Nom"
        d-type="text"
        label="Prénom"
        :d-model.sync="data.lastName"
      >
      </dok-input>
    </div>
    <!-- Spécialité -->
    <div class="w-full flex flex-col gap-y-1">
      <label class="font-EffraR text-base text-gray-800">Specialite</label>
      <specialitys
        custem-class="specialites-input"
        :no-multi="true"
        :selected-items.sync="data.specialities"
      />
    </div>
    <!-- Email -->
    <dok-input
      size="lg"
      d-placeholder="example@dok.ma"
      d-type="text"
      label="Email"
      :d-model.sync="data.email"
    >
      <template v-slot:leftIcon>
        <img src="/icons/mail.png" alt="email" />
      </template>
    </dok-input>
    <!-- Number Phone -->
    <div class="flex flex-row items-end gap-x-3">
      <!-- Code Country -->
      <div class="w-3/12">
        <dok-select
          size="lg"
          custom-class="w-full"
          label="Téléphone mobile"
          :d-model.sync="data.country_code"
        >
          <template v-slot:options>
            <option selected>+212</option>
          </template>
          <template v-slot:rightIcon>
            <img src="/svg/chevron-down .svg" />
          </template>
          <template v-slot:leftIcon>
            <svg
              width="24"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72c.127.96.361 1.903.7 2.81a2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45c.907.339 1.85.574 2.81.7A2 2 0 0 1 22 16.92z"
                stroke="#333638"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </template>
        </dok-select>
      </div>
      <!-- Number -->
      <div class="w-9/12">
        <dok-input
          size="lg"
          d-placeholder="6 31 23 04 53"
          d-type="text"
          :d-model.sync="data.mobile"
        >
        </dok-input>
      </div>
    </div>
    <!-- Actions -->
    <dok-button
      size="lg"
      bg="blue"
      :custom-class="
        `
        flex items-center justify-center gap-x-2 font-EffraM
        ${checkFilds ? '' : 'bg-opacity-60 cursor-not-allowed'}
      `
      "
      @click.native="checkFilds && !isLoading ? onCreate() : () => {}"
    >
      <svg
        v-if="isLoading"
        width="25"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="animate-spin"
      >
        <path
          d="M12.5 2v4"
          stroke="#fff"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.5 18v4"
          stroke="#FCFDFF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="m5.43 4.93 2.83 2.83M16.74 16.24l2.83 2.83M2.5 12h4M18.5 12h4M5.43 19.07l2.83-2.83M16.74 7.76l2.83-2.83"
          stroke="#fff"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <template v-if="!isLoading">
        <svg
          width="18"
          height="18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5 1.5 8.25 9.75M16.5 1.5l-5.25 15-3-6.75-6.75-3 15-5.25z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        Envoyer l'invitation
      </template>
    </dok-button>
  </div>
</template>

<script>
// Components
const specialitys = () =>
  import("@/views/global-components/SpecialitiesSelect");
// Event Bus
import { EventBus } from "@/event-bus";

export default {
  components: {
    specialitys
  },
  data() {
    return {
      isLoading: false,
      data: {
        specialities: []
      }
    };
  },
  computed: {
    checkFilds() {
      return true;
    }
  },
  methods: {
    async onCreate() {
      await this.$store.dispatch("social/SENT_INVITATION", {
        data: {
          recipient: {
            ...this.data
          }
        },
        onData: ({ ok, data }) => {
          if (ok) {
            EventBus.$emit("flashDok", {
              type: "success",
              message: "Invitation envoyée avec succès",
              timerAlert: 8000
            });

            EventBus.$emit("CLOSE_INVITER_CONFRERE");

            this.$nextTick(() => {
              this.data = {};
            });
          }
        }
      });
    }
  }
};
</script>

<style></style>
