<template>
  <div class="bg-white p-8 rounded-lg flex flex-col gap-y-6">
    <!-- LOADER FALSE -->
    <template v-if="!loader_search">
      <!-- if has data -->
      <template v-if="list_search.length">
        <h1 class="font-EffraM text-xl text-black">
          ({{ list_search.length }}) praticiens trouvés
        </h1>

        <div class="flex flex-wrap gap-x-5 gap-y-4">
          <!-- FIRST CARD INFORMATION -->
          <div
            class="bg-[#f6faff] hover:shadow-card w-cardNetwork rounded-lg p-4 flex flex-col gap-y-5"
          >
            <div class="flex-1 flex flex-col gap-y-3">
              <!-- Name & Avatar -->
              <div class="flex flex-col gap-y-2">
                <!-- Doctor Image Profile -->
                <div class="relative h-181 overflow-hidden">
                  <img
                    src="/artwork/no-data.svg"
                    class="h-181 w-full object-cover"
                  />
                </div>
              </div>

              <!-- Other Informations -->
              <div class="flex flex-col gap-y-0">
                <div class="text-base font-EffraM text-dokBlue-ultra">
                  Le confrère que vous recherchez n'a pas encore rejoint dok
                  Pro?
                </div>
                <div class="text-sm font-EffraR text-dokBlue-ultra">
                  Invitez des praticiens pour qu'ils profitent de toutes les
                  fonctionnalités que nous offrons.
                </div>
              </div>
            </div>

            <dok-button bg="blue" size="md" @click.native="ON_CLICK_INVITE"
              >Inviter des confères</dok-button
            >
          </div>

          <card-search
            v-for="(card, index) in list_search"
            :key="index"
            :data="card"
          />
        </div>
      </template>

      <!-- if null -->
      <template v-if="!list_search.length">
        <div class="w-full flex flex-col gap-y-8 items-center justify-center">
          <img
            src="/artwork/no-data.svg"
            style="width: 284.4px; height: 184.7px;"
          />

          <div class="flex flex-col w-full items-center justify-center gap-y-3">
            <h1 class="font-EffraM text-2xl text-black text-center">
              Le confrère que vous recherchez n'a pas <br />
              encore rejoint dok Pro?
            </h1>

            <p class="text-center font-EffraR text-base">
              Invitez des praticiens pour qu'ils profitent de toutes
              fonctionnalités que nous offrons.
            </p>
          </div>

          <dok-button
            @click.native="ON_CLICK_INVITE"
            size="lg"
            custom-class="px-10 font-EffraM flex items-center gap-x-2"
          >
            <svg
              width="25"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m22.5 2-11 11M22.5 2l-7 20-4-9-9-4 20-7z"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Inviter confrère
          </dok-button>
        </div>
      </template>
    </template>

    <!-- LOADER -->
    <div class="flex items-center justify-center" v-if="loader_search">
      <div class="w-300 mx-auto px-5">
        <div class="p-5 px-8 flex justify-center gap-x-8">
          <div
            class="bg-dokBlue-light w-8 h-8 rounded-full animate-bounce blue-circle"
          ></div>
          <div
            class="bg-rosDok-ultra w-8 h-8 rounded-full animate-bounce red-circle"
          ></div>
          <div
            class="bg-dokBlue-ultra w-8 h-8 rounded-full animate-bounce green-circle"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import algoliasearchHelper from "algoliasearch-helper";
import { EventBus } from "@/event-bus";

// Components
import cardSearch from "../layouts/card_search.vue";

export default {
  components: {
    cardSearch
  },
  data() {
    return {
      queryAlgolia: algoliasearchHelper(
        algoliasearch("2BJUOM1BDB", "7906f008c0346b78f39d051fa293387f"),
        "dokma_doctors",
        {
          facets: ["cityName", "firstName", "lastName"],
          hitsPerPage: 15
        }
      ),
      loader_search: true,
      list_search: []
    };
  },
  created() {
    this.listDoctors();
  },
  mounted() {
    EventBus.$on("SOCIAL_NETWORK_EMIT_SEARCH", data => {
      this.listDoctors(data);
    });
  },
  methods: {
    ON_CLICK_INVITE() {
      EventBus.$emit("MODAL_INVITER_CONFRERE");
    },
    listDoctors(data = null) {
      this.loader_search = true;

      this.queryAlgolia = algoliasearchHelper(
        algoliasearch("2BJUOM1BDB", "7906f008c0346b78f39d051fa293387f"),
        "dokma_doctors",
        {
          facets: ["cityName", "firstName", "lastName", "languages"],
          hitsPerPage: 8
        }
      );

      this.queryAlgolia.on("result", event => {
        this.pagination = {
          nbPages: event.results.nbPages,
          page: event.results.page
        };

        this.list_search = event.results.hits;

        this.$nextTick(() => {
          this.loader_search = false;
        });
      });

      let queryParams = this.queryAlgolia;
      let searchKeywords = [];

      if (data && data.city) {
        searchKeywords.push(data.city);
      }

      if (data && data.name) {
        searchKeywords.push(data.name);
      }

      if (data && data.specialite) {
        searchKeywords.push(data.specialite);
      }

      queryParams
        .setQuery(searchKeywords.join(" ").replace("maroc", ""))
        .search();
    }
  }
};
</script>
